import { create } from 'zustand';

import { InfoItem } from '../interfaces';

interface IArticleStore{
  infoCenter?: InfoItem[]
  setInfoCenter: (infoCenter: InfoItem[]) => void
  faq?: InfoItem[]
  setFaq: (faq: InfoItem[]) => void

  reset: () => void
}

export const articlesStore = create<IArticleStore>((set) => ({
  setInfoCenter: (infoCenter) => set((state) => ({ ...state, infoCenter })),
  setFaq: (faq) => set((state) => ({ ...state, faq })),
  reset: () => set((state) => ({ ...state, infoCenter: undefined, faq: undefined })),
}));
