export const BACK = 'חזרה';

export const ADDITIONAL_INFO = 'למידע נוסף';

export const LOADING = 'טוען...';

export const HOME_PATH = '/main';

export const GRAPH_MARGIN_ARRAY = [
  'ml-[10%]',
  'ml-[20%]',
  'ml-[30%]',
  'ml-[40%]',
  'ml-[50%]',
  'ml-[60%]',
  'ml-[70%]',
  'ml-[80%]',
  'ml-[90%]',
];

export const INVALID_TOKEN_MESSAGE = 'נותקת עקב חוסר פעילות, להמשך צפייה וביצוע פעולות יש להתחבר מחדש';
export const INVALID_TOKEN_BUTTON_TEXT = 'אישור';
export const CONTINUE_CONSULTING = 'להמשך ייעוץ לחץ כאן';
export const BACK_HOME_PAGE = 'חזרה לעמוד הבית';

export const NO_DATA_CONTENT = 'עוד כמה פרטים קטנים ותוכלו לקבל הצעות שהכנו במיוחד עבורכם. אנא צרו קשר עימנו באמצעות אחת מהדרכים הבאות:';

export const COLORS = {
  DEEP_SKY_BLUE: '#4775EA',
  DARK_NAVY_BLUE: '#212F61',
  LIGHT_GRAY: '#D9D9D9',
};

export const NO_DATA = 'אין נתונים';

export const ACCEPTED_FILES_TYPES = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg'],
};

export const MAX_FILES = 1;

export const MAX_SIZE = 20000000;

export const LOAN_REQUEST_FETCH_TIME = 120000;

export const TEST_VERSION = 'גרסת הרצה';
