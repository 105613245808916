import Swal, { SweetAlertOptions } from 'sweetalert2';

import VideoPlayer from '../components/VideoPlayer';

import { LoanRequest, LoanStatus } from '../interfaces';

import firework from '../assets/images/firework.gif';

import { APPROVED_LOAN_REQUEST, APPROVED_LOAN_REQUEST_VIDEO, MAIN_LOAN_REQUEST_TEXT } from '../constants';

export const mimoonPopupOptions = ({ customClass, ...options }: SweetAlertOptions): SweetAlertOptions => ({
  showConfirmButton: false,
  showCloseButton: true,
  position: 'center',
  inputAutoFocus: false,
  heightAuto: false,
  ...options,
  customClass: typeof customClass === 'string' ? customClass : {
    popup: 'grid w-11/12 text-[#212F61]',
    ...customClass,
    title: `mt-3 ${customClass?.title || ''}`,
    closeButton: `text-[#212F61] focus:shadow-none text-[30px] !ml-auto ${customClass?.closeButton || ''}`,
  },
});

export const approvedPopupOptions = ({ productName }: LoanRequest<LoanStatus.APPROVED>) => mimoonPopupOptions({
  title: MAIN_LOAN_REQUEST_TEXT.status,
  html:
  <div className='text-sm text-greyed mt-0'>
    <div className='flex justify-center items-center'>
      <span className='ml-1'>
        {MAIN_LOAN_REQUEST_TEXT.loan_type}
      </span>
      <span className='font-semibold'>
        {productName}
      </span>
      <img alt='firework' src={firework} width={60} />
    </div>
    <div className='m-2 shadow-video rounded-lg overflow-hidden'>
      <VideoPlayer videoUrl={APPROVED_LOAN_REQUEST_VIDEO} />
    </div>
  </div>,
  footer: APPROVED_LOAN_REQUEST.footer,
  customClass: {
    title: 'text-[16px] row-start-2 row-end-2',
    htmlContainer: 'row-start-3 row-end-3 !m-0',
    footer: 'text-[12px] leading-5 font-normal',
  },
});

export const videoPlayerPopupOptions = (title: string, videoUrl: string) => mimoonPopupOptions({
  title,
  html: <VideoPlayer
    videoUrl={videoUrl}
    onEnded={() => {
      if (Swal.isVisible()) {
        Swal.close();
      }
    }}
  />,
  showCloseButton: false,
  customClass: {
    container: 'p-0 !bg-[#000000A8]',
    popup: 'bg-transparent',
    htmlContainer: '!m-0',
    title: 'p-0 ml-auto text-white text-xl pb-[12px]',
  },
});
