import { CreditRatingDetailColor } from '../../interfaces';

import { ReactComponent as Megaphone } from '../../assets/icons/announcement-megaphone.svg';
import { ReactComponent as Coins } from '../../assets/icons/coins.svg';
import { ReactComponent as graphBarIncrease } from '../../assets/icons/graphBarIncrease.svg';
import { ReactComponent as RingingBell } from '../../assets/icons/ringingBell.svg';
import { ReactComponent as ThumbLike } from '../../assets/icons/thumb-like.svg';
import { ReactComponent as Time } from '../../assets/icons/time.svg';

export const creditRatingMainText = {
  your_credit_rating: 'דירוג האשראי שלך',
  update_at: 'עודכן ב-',
  high: 'גבוה',
  medium: 'בינוני',
  low: 'נמוך',
  all_info_credit_rating: 'לכל נתוני דירוג האשראי שלי',
};

export const creditRatingText = {
  title: 'דו״ח נתוני דירוג האשראי שלך',
  last_updated_at: 'תאריך עדכון אחרון:',
  distribution: 'על פי לשכת האשראי',
};

export const GENERAL_RATING_REASONS = 'סיבות כלליות לדירוג';

export const GENERAL_REASONS_RATING_ICONS = [
  { Icon: Coins, color: '#C8FFDE' },
  { Icon: graphBarIncrease, color: '#E0C8FF' },
  { Icon: Time, color: '#FFE2C8' },
];

export const CREDIT_RATING_CATEGORIES = [
  {
    id: 'negative',
    title: 'נתונים שליליים שחשוב לשפר',
    icon: RingingBell,
    color: CreditRatingDetailColor.RED,
    order: 1,
  },
  {
    id: 'improve',
    title: 'נתונים שמומלץ לשפר',
    icon: Megaphone,
    color: CreditRatingDetailColor.YELLOW,
    order: 2,
  },
  {
    id: 'positive',
    title: 'נתונים חיוביים',
    icon: ThumbLike,
    color: CreditRatingDetailColor.GREEN,
    order: 3,
  },
];

export const CREDIT_RATING_TOOLTIP = 'דירוג אשראי הוא ציון שנקבע על ידי לשכות האשראי, הוא נועד על מנת להעריך את יכולתם של אנשים/עסקים לפרוע הלוואות או לעמוד בתשלומים עתידיים שהתחייבו לבצע.';

export const SEVERITY = {
  negative: 'חמור',
  neutral: 'בינוני',
  positive: 'חיובי',
} as const;

export const DEFAULT_DATE = '0001-01-01T00:00:00';

export const REPORT_ESSENCE_TITLE = {
  arrearsInPayments: 'פיגור בתשלומים',
  nonPaymentOfLoan: 'אי תשלום הלוואה מהאירוע הראשון',
  significanceForNonPaymentIndication: 'מובהקות לאי פרעון לעניין חיווי',
  significanceForNonPaymentExclusion: 'מובהקות לאי פרעון לעניין אי הכללה',
  checkAlertLetters: 'מכתבי התראה על שיקים',
  returnOfChecks: 'החזרת שיקים בשנה האחרונה ',
  disregardedStandingOrders: 'ה.קבע שלא כובדו בשנה האחרונה',
  executionLimitedCharged: 'הוצל"פ חייב מוגבל באמצעים',
  executionTransactionsInProgress: 'עסקאות בטיפול הוצאה לפועל',
  execution: 'הוצאה לפועל',
  insolvency: 'הכונס הרשמי / חדל"פ',
  limitedAccountsIsraelBank: 'בנק ישראל - חשבונות מוגבלים',
  legalProceedings: 'הליך משפטי',
  restructuringOfProblematicDebt: 'ארגון מחדש של חוב בעייתי',
};

export const NO_REPORT_ESSENCE_DESC = '״תיאור הנתונים לוקח זמן. נעדכן ברגע שיהיה מוכן.״';

export const CREDIT_RATING_VIDEO = {
  TITLE: 'מה זה דירוג אשראי?',
  URL: `${process.env.REACT_APP_VIDEOS_BASE_URL}/bdi-rating.mp4`,
};
