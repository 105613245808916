import { create } from 'zustand';

import { Insight, LoanDetails, LoanType, Recommendations, ReportEssence } from '../interfaces';

interface IBdiStore{
  modifiedOn: string | null
  setModifiedOn:(modifiedOn?: string) => void

  recommendations?: Recommendations[]
  setRecommendations: (recommendations?: Recommendations[]) => void

  loans?: LoanType[]
  setLoans: (loans?: LoanType[]) => void

  ratingReasons?: string[]
  setRatingReasons: (ratingReasons?: string[]) => void

  reportEssences?: ReportEssence | null
  setReportEssences: (reportEssences?: ReportEssence) => void

  previousLoans?: LoanDetails[]
  setPreviousLoans: (previousLoans?: LoanDetails[]) => void

  insights?: Insight[]
  setInsights: (insights?: Insight[]) => void

  reset: () => void
}

const defaultStoreData = {
  loans: undefined,
  recommendations: undefined,
  ratingReasons: undefined,
  reportEssences: undefined,
  modifiedOn: '',
  previousLoans: undefined,
  insights: undefined,
};

export const bdiStore = create<IBdiStore>((set) => ({
  ...defaultStoreData,
  setModifiedOn: (modifiedOn) => set((state) => ({ ...state, modifiedOn: modifiedOn ?? state.modifiedOn ?? null })),
  setRecommendations: (recommendations) => set((state) => ({ ...state, recommendations: recommendations ?? state.recommendations ?? [] })),
  setLoans: (loans) => set((state) => ({ ...state, loans: loans ?? state.loans ?? [] })),
  setRatingReasons: (ratingReasons) => set((state) => ({ ...state, ratingReasons: ratingReasons ?? state.ratingReasons ?? [] })),
  setReportEssences: (reportEssences) => set((state) => ({ ...state, reportEssences: reportEssences ?? state.reportEssences ?? null })),
  setPreviousLoans: (previousLoans) => set((state) => ({ ...state, previousLoans: previousLoans ?? state.previousLoans ?? [] })),
  setInsights: (insights) => set((state) => ({ ...state, insights: insights ?? state.insights ?? [] })),

  reset: () => set((state) => ({ ...state, ...defaultStoreData })),
}));
