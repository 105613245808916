import { Link } from 'react-router-dom';

import { GenericLeftArrow } from '../../assets/genericLeftArrow';

import { ADDITIONAL_INFO, COLORS, GENERAL_REASONS_RATING_ICONS } from '../../constants';

interface IProps {
  index: number
  content: string
}

const CreditRatingCarouselCard = ({
  content, index,
}: IProps) => {
  const { color, Icon } = GENERAL_REASONS_RATING_ICONS[index % GENERAL_REASONS_RATING_ICONS.length];
  return (
    <div className='pl-[11px] h-full'>
      <div className='w-full h-full rounded-[21px] px-[17px] py-[18px] flex flex-col bg-white direction-rtl' style={{ backgroundColor: color }}>
        <div className='text-sm flex h-full'>
          <div className='bg-white h-fit ml-[17px] rounded-full my-auto'>
            <Icon className='p-2 h-[40px] w-[40px]' />
          </div>
          <div className='flex flex-col'>
            <div className='flex grow'>
              <p className='font-medium my-auto leading-4 text-right text-[12px]'>
                {content}
              </p>
            </div>
            <Link className='flex items-center gap-1 text-[12px]' to='/info-center'>
              <button className='font-bold' type='button'>{ADDITIONAL_INFO}</button>
              <GenericLeftArrow strokeColor={COLORS.DARK_NAVY_BLUE} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreditRatingCarouselCard;
