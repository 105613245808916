import { useCallback, useRef, useState } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';

import customFetch from '../axios/axiosInstance';

import { GET } from '../constants';

// TODO: handle error according to API response
export const useFetch = () => {
  const [error, setError] = useState<AxiosError| null>(null);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async <T>(url: string, {
    headers,
    method = GET,
    ...requestConfig
  }: Omit<AxiosRequestConfig, 'url'> = {}) => {
    try {
      const accessToken = sessionStorage.getItem('accessToken');

      setLoading(true);
      const response = await customFetch<T>({
        method,
        url,
        headers: accessToken ? { ...headers, Authorization: `Bearer ${accessToken}` } : headers,
        ...requestConfig,
      });

      setLoading(false);
      setError(null);
      return response.data;
    } catch (e) {
      console.error(e);
      setLoading(false);
      setError(e as AxiosError);
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return {
    request, error, clearError, loading,
  };
};

export default useFetch;
