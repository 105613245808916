import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Carousel from '../components/Carousel';
import CreditRatingCarouselCard from '../components/CreditRating/CreditRatingCarouselCard';
import CreditRatingData from '../components/CreditRating/CreditRatingData';
import CreditRatingHeader from '../components/CreditRating/CreditRatingHeader';
import CreditRatingProgressBar from '../components/CreditRating/CreditRatingProgressBar';
import Back from '../components/Generic/Back';
import Loading from '../components/Loading';

import useApiRequests from '../hooks/useApiRequests';
import { bdiStore, userStore } from '../store';
import { dateTimeFormat } from '../utils/dateUtils';

import {
  creditRatingText,
  GENERAL_RATING_REASONS,
  MAIN,
} from '../constants';

import '../styles/slickCarousel.css';

const CreditRatingInfoPage = () => {
  const {
    modifiedOn,
    ratingReasons,
    reportEssences,
    setModifiedOn,
    setRatingReasons,
    setReportEssences,
  } = bdiStore();

  const navigate = useNavigate();

  const { user } = userStore();
  const { error, getUserBDI } = useApiRequests();

  useEffect(() => {
    if (!user?.bdiRating) navigate(MAIN);

    getUserBDI().then(data => {
      if (data) {
        setRatingReasons(data.ratingReasons);
        setReportEssences(data.reportEssence);
        setModifiedOn(data.modifiedOn);
      }
    });
  }, []);

  useEffect(() => {
    if (error) navigate(MAIN);
  }, [error]);

  const isLoading = (ratingReasons === undefined)
    || (reportEssences === undefined)
    || (modifiedOn === undefined);

  return (
    <div className='overscroll-contain overflow-x-hidden'>
      {isLoading && <Loading />}
      <div>
        <Back />
        <div className='text-white mt-5 mr-6'>
          <h1 className='font-bold text-xl'>{creditRatingText.title}</h1>
          {
            modifiedOn && (
              <>
                <span>{`${creditRatingText.last_updated_at} `}</span>
                <span>{dateTimeFormat(new Date(modifiedOn!))}</span>
              </>
            )
          }
        </div>

        <div className='p-[15px]'>
          {user?.bdiRating ? (
            <div className='relative w-full rounded-[21px] mt-[12px] px-[17px] py-[18px] flex flex-col bg-white'>
              <div className='mb-4'>
                <CreditRatingHeader />
              </div>
              <div className='flex pb-5 justify-center'>
                <CreditRatingProgressBar />
              </div>
            </div>
          ) : null}

          {ratingReasons && ratingReasons.length > 0 && (
          <div className='mt-14 z-50 w-screen'>
            <Carousel
              CarouselCardComponent={CreditRatingCarouselCard}
              className='ml-[15px]'
              content={ratingReasons.map((ratingReason, index) => ({ content: ratingReason, index }))}
              title={GENERAL_RATING_REASONS}
            />
          </div>
          )}
          {reportEssences && <CreditRatingData {...reportEssences} />}
        </div>
      </div>
    </div>
  );
};

export default CreditRatingInfoPage;
