import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { GenericLeftArrow } from '../../assets/genericLeftArrow';
import { LoanRequest as LoanRequestType, LoanStatus } from '../../interfaces';
import { approvedPopupOptions } from '../../utils/popup';

import { ReactComponent as Approved } from '../../assets/icons/approved.svg';
import { ReactComponent as Clock } from '../../assets/icons/clock.svg';
import { ReactComponent as Rejected } from '../../assets/icons/rejected.svg';

import { ADDITIONAL_INFO, COLORS, MAIN_LOAN_REQUEST_TEXT } from '../../constants';

interface ILoanRequestStatus<T extends LoanStatus> {
  index: number
  loanRequest: LoanRequestType<T>
}

const LoanRequest = <T extends LoanStatus, >({ index, loanRequest }: ILoanRequestStatus<T>) => {
  const navigate = useNavigate();
  const displayAdditionalInfo = !(loanRequest.statusName === LoanStatus.PENDING
    || loanRequest.statusName === LoanStatus.DONE);

  const handleAdditionalInfo = () => {
    if (loanRequest.statusName === LoanStatus.APPROVED) {
      return withReactContent(Swal).fire(approvedPopupOptions({
        productName: loanRequest.productName ?? '',
      } as LoanRequestType<LoanStatus.APPROVED>));
    }
    return navigate(`/loan-request/${index}`);
  };

  useEffect(() => () => Swal.close(), []);

  const StatusComponent = {
    pending: <Clock />,
    approved: <Approved />,
    rejected: <Rejected />,
  };

  const status = () => {
    if (loanRequest.statusName === LoanStatus.APPROVED) return StatusComponent.approved;

    if (loanRequest.statusName === LoanStatus.REJECTED) return StatusComponent.rejected;

    return StatusComponent.pending;
  };
  return (
    <div className='flex flex-col bg-white w-full shadow-card rounded-[21px] px-[17px] py-4 '>
      <div className='flex justify-between'>
        <p className='font-bold'>{MAIN_LOAN_REQUEST_TEXT.status}</p>
        <div className='flex gap-x-1.5 loan-application-status items-center py-1 px-1.5 text-[12px] rounded'>
          {status()}
          <span>{loanRequest.statusName}</span>
        </div>
      </div>
      <span className='text-[12px] font-normal text-greyed'>{loanRequest.productName}</span>
      {displayAdditionalInfo && (
      <button
        className='flex w-full justify-center mt-3.5 text-sm text-buttonBlue font-semibold items-center border-t border-[#EDEEF2] pt-4'
        type='button'
        onClick={handleAdditionalInfo}
      >
        <span className='ml-2'>{ADDITIONAL_INFO}</span>
        <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
      </button>
      )}
    </div>
  );
};
export default LoanRequest;
