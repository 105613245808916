import { LOANS_TYPES_SUB_TITLE, LOANS_TYPES_TITLE } from '../../constants';

const LoansTypesHeader = () => (
  <div className='mt-5 mr-6 text-white '>
    <h1 className='font-bold text-xl'>{LOANS_TYPES_TITLE}</h1>
    <span className='font-light text-sm'>{LOANS_TYPES_SUB_TITLE}</span>
  </div>
);

export default LoansTypesHeader;
