import React, {
  useEffect, useRef, useState,
} from 'react';

import Loading from '../Loading';
import Timer from './Timer';

import { RateLimitExceeded } from '../../interfaces';
import { loginStore } from '../../store/loginStore';

import { BLOCK_TIME, loginPageText, OTP_MESSAGE_LENGTH } from '../../constants';

import '../../styles/OTPInput.css';

export interface IOTPInputInterface {
    loading: boolean
    resendOtp: (phoneNumber: string) => Promise<any>
}
const OTPInput = ({ loading, resendOtp } : IOTPInputInterface) => {
  const {
    canResend,
    formData,
    inputValidated,
    newOtpCode,
    serverError,
    setCanResend,
    setInputValidated,
    setOTPCode,
    setServerError,
  } = loginStore();

  const [otp, setOTP] = useState<string[]>(new Array(OTP_MESSAGE_LENGTH).fill(''));

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  const handleOtpChange = (value: string, index: number) => {
    if (Number.isNaN(Number(value)) || value === ' ') {
      return;
    }
    const newOTP = [...otp];
    newOTP[index] = value;
    setOTP(newOTP);

    if (index < 5 && inputRefs.current[index + 1]) {
      setTimeout(
        () => inputRefs.current[index + 1]?.focus(),
        0,
      );
    }
    setInputValidated(!newOTP.includes(''));
    if (!newOTP.includes('')) {
      setOTPCode(newOTP.join(''));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newOTP = [...otp];
      const position = !otp[index] ? index - 1 : index;
      newOTP[position] = '';
      if (index < newOTP.length) {
        const lastIndex = newOTP.indexOf('', newOTP.indexOf('') + 1);
        for (let i = index; i < (lastIndex === -1 ? 6 : lastIndex); i += 1) {
          newOTP[i] = newOTP[i + 1] ? newOTP[i + 1] : '';
        }
      }
      setOTP(newOTP);
      inputRefs.current[position]?.focus();
      setInputValidated(!newOTP.includes(''));
      return;
    }
    handleOtpChange(e.key, index);
  };

  const handleNewOtp = async () => {
    await resendOtp(formData.phoneNumber);
    setOTP(new Array(OTP_MESSAGE_LENGTH).fill(''));
    newOtpCode();
    setCanResend(false);
    setServerError(null);
    setTimeout(() => inputRefs.current[0]?.focus(), 0);
  };

  const handlePasteClipboard = (otp: string) => {
    setOTP(otp.split(''));
    setInputValidated(true);
    setOTPCode(otp);
  };

  const resendOtpComponents = {
    otp: (
      <div className='mt-[15px]'>
        <div className='flex justify-center'>
          <div className='mx-1'>
            {loginPageText.otp.didnt_receive_code}
          </div>
          <button className='text-buttonBlue font-bold' type='button' onClick={handleNewOtp}>
            {loginPageText.otp.send_code_again}
          </button>
        </div>
      </div>),
    otpError: (
      <div className='flex justify-center gap-x-0.5'>
        <span>{loginPageText.otp.resend_otp_error}</span>
        <Timer
          seconds={(serverError?.response?.data as RateLimitExceeded)?.timeRemaining ?? BLOCK_TIME}
          setTimerFinished={() => {
            setCanResend(true);
          }}
          timerFinished={false}
        />
      </div>),
  };

  const resendContent = () => {
    if (canResend) return resendOtpComponents.otp;

    return resendOtpComponents.otpError;
  };

  useEffect(() => {
    document.getElementById('otp0')?.focus();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className='mx-[28px] mt-[64px]'>
        <div className='font-bold text-base'>{loginPageText.otp.title}</div>
        <div className='text-sm mt-[15px]'>
          {loginPageText.otp.subtitle}
        </div>
        <div className='flex flex-col text-center mt-8 text-small leading-small'>
          <div className='grid sm:grid-cols-6 xxs:grid-cols-3 xxs:gap-y-2 gap-x-2 mx-auto direction-ltr'>
            {[...Array(OTP_MESSAGE_LENGTH)].map((_, index) => (
              <input
                key={index}
                ref={(el) => {
                  inputRefs.current[index] = el;
                }}
                autoComplete='off'
                className={`py-4 px-3 text-center rounded-[8px] border-[1px] text-lg
                  ${inputValidated === false && otp[otp.length - 1] !== '' ? 'border-[#FE5C42]' : 'border-[#D9D9D9]'} 
                  ${index === 0 && otp[index] === '' ? 'animate-blink' : 'caret-transparent'} focus:outline-none focus:border-buttonBlue disabled:bg-white`}
                disabled={otp.join('').length < index}
                id={`otp${index}`}
                type='tel'
                value={otp[index] || ''}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, '');
                  if (value.length === OTP_MESSAGE_LENGTH) handlePasteClipboard(value);
                }}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>
          <div className={`mt-[12px] select-none ${(!otp.includes('') && inputValidated === false) ? 'text-[#FE5C42]' : 'text-white'}`}>
            {loginPageText.otp.invalid_content}
          </div>
          {resendContent()}
        </div>
      </div>
    </>
  );
};
export default OTPInput;
