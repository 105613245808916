import { useEffect } from 'react';

import PageLayout from '../components/Layout/PageLayout';
import LoanType from '../components/LoansTypes';
import EmptyState from '../components/LoansTypes/EmptyState';
import LoansTypesHeader from '../components/LoansTypes/LoansTypesHeader';

import useApiRequests from '../hooks/useApiRequests';
import { bdiStore } from '../store';

const LoansTypes = () => {
  const { getOpportunity, postOpportunity } = useApiRequests();
  const { loans, setLoans } = bdiStore();

  const handleContact = async (product: string) => postOpportunity({ product });

  useEffect(() => {
    getOpportunity().then((data) => setLoans(data));
  }, []);

  return (
    <PageLayout header={<LoansTypesHeader />} loading={!loans}>
      <div className='flex flex-col gap-y-10 my-8 mx-6'>
        {loans && (
          loans.length > 0 ? (
            loans.map((loan) => (
              <LoanType
                key={`loan-offer-${loan.product}`}
                handleContact={() => handleContact(loan.product)}
                {...loan}
              />
            ))
          ) : (
            <EmptyState />
          ))}
      </div>
    </PageLayout>

  );
};

export default LoansTypes;
