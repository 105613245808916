import React from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { GenericLeftArrow } from '../../../assets/genericLeftArrow';
import extractTextFromHTML from '../../../utils/extractTextFromHTML';
import { videoPlayerPopupOptions } from '../../../utils/popup';

import { ReactComponent as Play } from '../../../assets/icons/play.svg';

import { ADDITIONAL_INFO, COLORS, INFO_CENTER } from '../../../constants';

export interface IMainCarouselCardProps {
  title: string,
  content: string[]
  video?: string
  button?: {
    content: string,
    navLink: string
  }
}

const MainCarouselCard = (props: IMainCarouselCardProps) => {
  const { button, content, title, video } = props;

  const handleVideoTooltip = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    withReactContent(Swal).fire(
      videoPlayerPopupOptions(title, extractTextFromHTML(video)),
    );
  };

  return (
    <div className='pl-[11px] h-full'>
      <div className='w-full h-full gap-y-2 rounded-[21px] px-[17px] py-[18px] flex flex-col bg-white direction-rtl'>
        <div className='flex items-center gap-x-2 font-bold text-small'>
          {video ? (
            <button type='button' onClick={handleVideoTooltip}>
              <Play />
            </button>
          ) : null}
          {title}
        </div>
        <div className='flex flex-col gap-y-3 text-greyed text-[12px]'>
          {content.map((data, i) => (
            <p key={`${i}-${data}`}>
              {data}
            </p>
          ))}
        </div>
        <Link className='flex text-buttonBlue gap-x-1.5 text-[12px] mt-auto items-center font-bold justify-end' to={button?.navLink ?? INFO_CENTER}>
          {button?.content ?? ADDITIONAL_INFO}
          <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
        </Link>
      </div>
    </div>
  );
};

export default MainCarouselCard;
