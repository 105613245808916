import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { videoPlayerPopupOptions } from '../../utils/popup';

import { ReactComponent as Play } from '../../assets/icons/play.svg';

import { CREDIT_RATING_VIDEO, creditRatingMainText, creditRatingText } from '../../constants';

const CreditRatingHeader = () => {
  const handleVideoTooltip = () => {
    withReactContent(Swal).fire(
      videoPlayerPopupOptions(CREDIT_RATING_VIDEO.TITLE, CREDIT_RATING_VIDEO.URL),
    );
  };

  return (
    <>
      <div className='flex items-center gap-2 b'>
        <h3 className='font-bold'>{creditRatingMainText.your_credit_rating}</h3>
        <button type='button' onClick={handleVideoTooltip}>
          <Play className='w-[22px] h-[22px]' />
        </button>
      </div>
      <span className='text-lightGrey text-sm'>{creditRatingText.distribution}</span>
    </>
  );
};

export default CreditRatingHeader;
