import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Suggestion from '../Suggestion';

import useApiRequests from '../../hooks/useApiRequests';
import { videoPlayerPopupOptions } from '../../utils/popup';

import { ReactComponent as Play } from '../../assets/icons/play.svg';

import {
  ADVISER, MAIN_LOAN_REQUEST_TEXT, REJECT_LOAN_VIDEO, REJECTED_DESCRIPTION, SOLUTIONS,
} from '../../constants';

interface IDetails {
    loanType: string,
    setPageDisplay: React.Dispatch<React.SetStateAction<'details' | 'assistant'>>
}
const Details = ({ loanType, setPageDisplay }: IDetails) => {
  const { postOpportunity } = useApiRequests();

  const handleAdvisor = async () => {
    await postOpportunity();
    setPageDisplay('assistant');
  };

  const handleVideoTooltip = () => {
    withReactContent(Swal).fire(
      videoPlayerPopupOptions(REJECT_LOAN_VIDEO.TITLE, REJECT_LOAN_VIDEO.URL),
    );
  };

  return (
    <>
      <div>
        <span className='ml-1 text-greyed'>{MAIN_LOAN_REQUEST_TEXT.loan_type}</span>
        <span className='font-semibold'>{loanType}</span>
      </div>
      <p className='my-4 text-greyed'>
        {REJECTED_DESCRIPTION}
      </p>
      <div className='mt-auto flex flex-col text-center mb-6'>
        <div className='flex justify-center mb-7 gap-x-1'>
          <span>{SOLUTIONS}</span>
          <button type='button' onClick={handleVideoTooltip}>
            <Play />
          </button>
        </div>
        <div className='layout rounded-3xl'>
          <Suggestion />
        </div>
        <button
          className='text-buttonBlue mt-3.5 font-semibold'
          type='button'
          onClick={handleAdvisor}
        >
          {ADVISER}
        </button>
      </div>
    </>
  );
};

export default Details;
