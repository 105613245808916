import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ConsultHeader from '../components/ConsultHeader';
import PageLayout from '../components/Layout/PageLayout';
import RejectedDetails from '../components/RejectedLoanRequest/RejectedDetails';

import useApiRequests from '../hooks/useApiRequests';
import { LoanRequest, LoanStatus } from '../interfaces';
import { userStore } from '../store';

import {
  MAIN, NO_DATA,
  REJECTED_REQUEST_HEADER,
} from '../constants';

const LoanRequestDetails = () => {
  const { index } = useParams();
  const navigate = useNavigate();

  const [loanRequest, setLoanRequest] = useState<LoanRequest<LoanStatus.REJECTED>>();
  const { loanRequests, setLoanRequests } = userStore();
  const { getLoanRequests, loading } = useApiRequests();

  const getRejectedLoanRequest = (loanRequests: LoanRequest<LoanStatus>[]) => {
    if (loanRequests.length === 0) return navigate(MAIN);

    const loanRequest = loanRequests[Number(index)];
    if (loanRequest && loanRequest.statusName === LoanStatus.REJECTED) return setLoanRequest(loanRequest as LoanRequest<LoanStatus.REJECTED>);

    return navigate(MAIN);
  };

  useEffect(() => {
    if (!loanRequests) {
      getLoanRequests().then((data) => {
        setLoanRequests(data);
        if (data) getRejectedLoanRequest(data);
      });
    } else {
      getRejectedLoanRequest(loanRequests);
    }
  }, []);

  return (
    <PageLayout header={<ConsultHeader title={REJECTED_REQUEST_HEADER} />} loading={!loanRequest || loading}>
      {loanRequest
        ? (
          <RejectedDetails
            {...loanRequest}
          />
        ) : (
          <span>{NO_DATA}</span>
        )}
    </PageLayout>
  );
};

export default LoanRequestDetails;
