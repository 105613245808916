import { useEffect } from 'react';

import AdviceCard from '../components/AdviceCard';
import EmptyData from '../components/EmptyData';
import PageLayout from '../components/Layout/PageLayout';
import PreviousLoanHeader from '../components/PreviousLoans/PreviousLoanHeader';
import PreviousLoanListCard from '../components/PreviousLoans/PreviousLoanListCard';

import useApiRequests from '../hooks/useApiRequests';
import { bdiStore } from '../store';

import { ReactComponent as Layers } from '../assets/icons/layers.svg';

import { LOAN_CONSOLIDATION } from '../constants';

const PreviousLoans = () => {
  const { getPreviousLoans, postOpportunity } = useApiRequests();
  const { previousLoans, setPreviousLoans } = bdiStore();
  const { beginning, bold, ending } = LOAN_CONSOLIDATION.title;

  useEffect(() => {
    getPreviousLoans()
      .then((data) => setPreviousLoans(data));
  }, []);

  const handleAdviceBody = async () => postOpportunity({ product: LOAN_CONSOLIDATION.productId });

  return (
    <PageLayout header={<PreviousLoanHeader />} loading={!previousLoans}>
      <div className='flex flex-col flex-grow mt-10 mb-3 mx-6 gap-y-10'>
        {previousLoans !== undefined && (
          previousLoans.length > 0 ? (
            <>
              <AdviceCard
                Img={Layers}
                description={LOAN_CONSOLIDATION.description}
                handleButton={handleAdviceBody}
              >
                <AdviceCard.Header>
                  <div>
                    {beginning}
                    {' '}
                    <span className='font-bold'>{bold}</span>
                    {' '}
                    {ending}
                  </div>
                </AdviceCard.Header>
              </AdviceCard>
              <div className='flex flex-col gap-y-7'>
                {previousLoans.map((loanOffer) => (
                  <PreviousLoanListCard
                    key={loanOffer.id}
                    loanOfferCard={loanOffer}
                  />
                ))}
              </div>
            </>
          ) : (
            <EmptyData />
          )
        )}
      </div>
    </PageLayout>
  );
};

export default PreviousLoans;
