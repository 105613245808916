import PageLayout from '../components/Layout/PageLayout';
import Details from '../components/PersonalProfile/Details';
import Docs from '../components/PersonalProfile/docs';
import Tab from '../components/PersonalProfile/Tab';
import ProfileIcon from '../components/ProfileIcon';

import { personalProfileStore } from '../store';

import { PERSONAL_PROFILE_TITLE, TABS } from '../constants';

const PersonalProfile = () => {
  const {
    tab,
  } = personalProfileStore();

  return (
    <PageLayout header={PERSONAL_PROFILE_TITLE}>
      <>
        <div className='flex justify-center w-fit mx-auto relative bottom-11'>
          <ProfileIcon />
        </div>
        <>
          <Tab />
          <div className='flex flex-col flex-grow mx-6 py-4'>
            {tab === TABS.DETAILS && <Details />}
            {tab === TABS.DOCUMENTS && <Docs />}
          </div>
        </>
      </>
    </PageLayout>

  );
};

export default PersonalProfile;
