interface GenericArrowProps {
  strokeColor: string
}

export const GenericLeftArrow = ({ strokeColor }: GenericArrowProps) => (
  <svg
    className='inline-block'
    fill='none'
    height='10'
    viewBox='0 0 6 10'
    width='6'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M5 9L1 5L5 1' stroke={strokeColor} strokeLinecap='round' strokeLinejoin='round' strokeWidth='1' />
  </svg>
);
