import axios from 'axios';

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});

customFetch.interceptors.request.use(
  config => config,
  error => Promise.reject(error),
);

customFetch.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config;
    if ((error?.response?.status === 401 || error?.response?.status === 403) && error?.response?.config.url !== '/token/refresh') {
      try {
        const response = await axios.post('/token/refresh', null, {
          baseURL: process.env.REACT_APP_BASE_URL,
          withCredentials: true,
        });
        const { accessToken } = response.data;
        sessionStorage.setItem('accessToken', accessToken);
        return customFetch({ ...originalRequest, headers: { Authorization: `Bearer ${accessToken}` } });
      } catch (refreshError) {
        sessionStorage.removeItem('accessToken');
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  },
);

export default customFetch;
