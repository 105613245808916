import { Link } from 'react-router-dom';

import { GenericLeftArrow } from '../../assets/genericLeftArrow';

import { ReactComponent as MoneyBag } from '../../assets/icons/moneybag.svg';

import { GENERIC_SUGGESTION, LOAN_TYPES } from '../../constants';

const Suggestion = () => (
  <Link className='flex border border-white font-semibold leading-4 bg-blur-card-wrapper p-4 text-white rounded-3xl text-[13px] bg-cover bg-no-repeat' to={LOAN_TYPES}>
    <div className='w-full flex items-center justify-between'>
      <div className='flex items-center'>
        <MoneyBag />
        <span className='flex pr-4 text-wrap text-sm'>{GENERIC_SUGGESTION}</span>
      </div>
      <GenericLeftArrow strokeColor='white' />
    </div>
  </Link>
);

export default Suggestion;
