import {
  ComponentType, JSX, ReactElement, useEffect, useRef,
} from 'react';
import Slider, { Settings } from 'react-slick';

import CarouselDot from './CarouselDot';

interface CarouselProps<T> {
  title: string;
  content: T[];
  CarouselCardComponent: ComponentType<T>;
  className?: string
}
const Carousel = <T, > ({
  CarouselCardComponent, className, content, title,
}:CarouselProps<T>) => {
  const slideRef = useRef<Slider | null>(null);

  const settings: Settings = {
    appendDots: (dots: ReactElement[]): JSX.Element => (
      <div>
        {dots.map((dot, index) => (
          <CarouselDot
            key={index}
            active={dot.props.className === 'slick-active'}
            onClick={dot.props.children.props.onClick}
          />
        ))}
      </div>
    ),
    arrows: false,
    dots: true,
    rtl: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    className: `-mr-[50px] ${className ?? ''}`,
    infinite: false,
  };

  useEffect(() => {
    if (slideRef.current) {
      slideRef.current.slickGoTo(content.length - 1, true);
    }
  }, []);

  return (
    <>
      <div className='font-bold text-[16px] text-white mb-[12px]'>
        {title}
      </div>
      <Slider
        ref={slideRef}
        {...settings}
      >
        {content.map((item:any, index:number) => (
          <CarouselCardComponent key={index} {...item} />
        ))}
      </Slider>
    </>
  );
};
export default Carousel;
