import { dateTimeFormat, isValidDateTimeFormat } from './dateUtils';

import { LoanLabels } from '../interfaces';

import { DEFAULT_DATE } from '../constants';

export const getValue = (detail: LoanLabels, value: string | number) => {
  if (value && value !== DEFAULT_DATE) {
    if (detail.isMoney) return `₪${value?.toLocaleString()}`;
    if (isValidDateTimeFormat(value)) return dateTimeFormat(new Date(value));
    return value;
  }
  return '';
};
