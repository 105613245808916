import { useEffect } from 'react';

import ArticleCollapses from '../components/Articles/ArticleCollapses';
import PageLayout from '../components/Layout/PageLayout';

import useApiRequests from '../hooks/useApiRequests';
import { articlesStore } from '../store';

import { FAQ_TITLE, INFO_EMPTY_STATE, INFO_ERROR_STATE } from '../constants';

const FaqPage = () => {
  const { error, getFaq, loading } = useApiRequests();
  const { faq, setFaq } = articlesStore();

  useEffect(() => {
    if (!faq || faq.length === 0) {
      getFaq().then(
        (data = []) => setFaq(data),
      );
    }
  }, []);

  const renderContent = () => {
    if (error) {
      return <span className='text-center text-gray-300 text-small'>{INFO_ERROR_STATE}</span>;
    }

    if (faq && faq.length > 0) {
      return <ArticleCollapses list={faq} />;
    }

    return <span className='text-center text-gray-300 text-small'>{INFO_EMPTY_STATE}</span>;
  };

  return (
    <PageLayout header={FAQ_TITLE} loading={!faq || loading}>
      <div className='flex flex-col w-10/12 mx-auto mt-5 pb-4 '>
        {renderContent()}
      </div>
    </PageLayout>
  );
};

export default FaqPage;
