import { IPrivacyPolicy } from '../../../interfaces';

export const privacyPolicySection = (section: IPrivacyPolicy, index: number, concatSection: string = '') => {
  if (!section.subContent) {
    return (
      <li key={`item-${section.content}`} className='grid grid-cols-[auto_1fr] gap-x-1 text-right'>
        <span className='w-fit'>{`${concatSection}${index + 1}. `}</span>
        <span>{section.content}</span>
      </li>
    );
  }

  return (
    <li key={`list-${section.content}`}>
      <div className='grid grid-cols-[auto_1fr] gap-x-1 text-right'>
        <span className='w-fit'>{`${concatSection}${index + 1}. `}</span>
        <span>{section.content}</span>
      </div>
      <ul className='flex flex-col gap-y-2 mt-2 mr-2 ml-0.5'>
        {section.subContent.map((subContents, subIndex) => privacyPolicySection(subContents, subIndex, `${`${concatSection}${index + 1}.`}`))}
      </ul>
    </li>
  );
};
