import Loading from '../components/Loading';
import Header from '../components/Main/Header';
import MainContent from '../components/Main/MainContent';
import NoBdi from '../components/Main/NoBdi';

import useApiRequests from '../hooks/useApiRequests';
import { bdiStore, userStore } from '../store';

const MainPage = () => {
  const {
    hasBdi,
    loanRequests,
    user,
  } = userStore();

  const {
    loans,
    recommendations,
  } = bdiStore();

  const { getMainPageData, getNoBdi, postOpportunity } = useApiRequests();

  const isLoading = (hasBdi && (!loans || !recommendations)) || !user || !loanRequests;

  return (
    <div className='overscroll-contain overflow-x-hidden'>
      {isLoading && <Loading />}
      <div className='flex flex-col gap-y-8 p-[15px]'>
        <Header />
        {
          hasBdi
            ? (
              <MainContent
                getMainPageData={getMainPageData}
                handleContact={postOpportunity}
              />
            )
            : (
              <NoBdi
                getNoBdi={getNoBdi}
                handleContact={postOpportunity}
              />
            )
        }
      </div>
    </div>
  );
};
export default MainPage;
