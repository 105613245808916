import { MutableRefObject, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import useFetch from './useFetch';

import { BDIReport, InfoItem, LoanDetails, LoanRequest, LoanRequestStatusType, LoanType, PostOpportunityBody, Product, User } from '../interfaces';
import { articlesStore, bdiStore, uiLogicStore, userStore } from '../store';

import { GET, LOGIN, POST, PUT } from '../constants';

const useApiRequests = () => {
  const navigate = useNavigate();
  const { logOut } = userStore();
  const { reset: resetBdi } = bdiStore();
  const { reset: resetArticles } = articlesStore();

  const { isApp } = uiLogicStore();
  const { error, loading, request } = useFetch();
  const controllerRef = useRef<AbortController | null>(null) as MutableRefObject<AbortController | null>;

  const verifyPhoneRequest = async (phoneNumber:string) => request<any>('/otp/initiate', { method: POST, data: { phoneNumber }, withCredentials: true });

  const verifyOTPRequest = async (phoneNumber:string, code:string) => request<any>('/otp/validate', {
    method: POST, data: { phoneNumber, code }, withCredentials: true,
  });

  const putPersonalProfile = async (putUserData: Partial<Omit<User, 'userId'>>) => request('/user', {
    method: PUT,
    data: { ...putUserData },
  });

  const getDocTypes = async () => request('/doc-types', { signal: controllerRef.current?.signal });

  const getDocs = async () => request('/docs/', { signal: controllerRef.current?.signal });

  const uploadDoc = async (typeId: string, files: File[]) => {
    try {
      const formData = new FormData();

      formData.append('typeId', typeId);
      files.forEach(file => formData.append('files', file));

      return await request('/docs', {
        method: POST,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const putUseMobileApp = async () => request('/user/set-use-mobile-app', { method: PUT });

  const postOpportunity = async (data?: PostOpportunityBody) => {
    try {
      await request('/contact', { method: POST, data });
    } catch (err) {
      console.log(err);
    }
  };

  const getMainPageData = async () => request('user/main', { signal: controllerRef.current?.signal });

  const removeRefreshToken = async () => request('/token/remove', { method: POST, withCredentials: true });

  const refreshToken = async () => request<{accessToken: string, user: User}>('/token/refresh', { method: POST, withCredentials: true });

  const getFaq = async () => request<InfoItem[]>('/faq', { signal: controllerRef.current?.signal });

  const getInfoCenter = async () => request<InfoItem[]>('/infocenter', { signal: controllerRef.current?.signal });

  const getNoBdi = async () => request('user/nobdi', { signal: controllerRef.current?.signal });

  const getOpportunity = () => request<LoanType[]>('/loans', { signal: controllerRef.current?.signal });

  const getPreviousLoans = async () => request<LoanDetails[]>('/previous-loans/', { signal: controllerRef.current?.signal });

  const getPreviousLoan = async (id: string) => request<LoanDetails>(`/previous-loans/${id}`, { signal: controllerRef.current?.signal });

  const getProducts = async () => request<Product[]>('/products', { signal: controllerRef.current?.signal });

  const getUserBDI = async () => request<BDIReport>('/bdi', { signal: controllerRef.current?.signal });

  const getLoanRequests = async () => request<LoanRequest<LoanRequestStatusType<any>>[]>('/loan-requests', { signal: controllerRef.current?.signal });

  useEffect(() => {
    controllerRef.current = new AbortController();

    return () => {
      if (controllerRef.current) {
        controllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    if (error && error?.response?.config.url === '/token/refresh') {
      if (isApp) {
        (window as any).Android?.logout();
      } else {
        logOut();
        resetBdi();
        resetArticles();
        navigate(LOGIN);
      }
    }
  }, [error]);

  return {
    loading,
    error,
    verifyPhoneRequest,
    verifyOTPRequest,
    putPersonalProfile,
    putUseMobileApp,
    getDocTypes,
    getDocs,
    uploadDoc,
    postOpportunity,
    getMainPageData,
    removeRefreshToken,
    refreshToken,
    getFaq,
    getInfoCenter,
    getOpportunity,
    getPreviousLoans,
    getPreviousLoan,
    getProducts,
    getLoanRequests,
    getNoBdi,
    getUserBDI,
  };
};
export default useApiRequests;
