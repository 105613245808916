export const mainHeaderText = {
  hiGreeting: 'היי',
  subtitle: 'ריכזתי עבורך את כל העדכונים החשובים.',
  noBdiSubtitles: 'מוזמן/ת לעיין ולהיעזר בחומרי המידע שאספתי עבורך',
};

export const MAIN_LOAN_REQUEST_TEXT = {
  status: 'סטטוס בקשה להלוואה',
  loan_type: 'סוג ההלוואה:',
} as const;

export const APPROVED_LOAN_REQUEST = {
  footer: 'נא להיות זמין בשעות הקרובות לשיחה מול נציג והמשך תהליך קבלת ההלוואה',
};

export const PERSONAL_INSIGHTS = 'תובנות היועץ';

export const PERSONAL_RECOMMENDATIONS = 'המלצות אישיות';

export const GENERIC_SUGGESTION = 'לכל סוגי ההלוואות שמתאימות לך';
export const LOAN_SUGGESTION_BUTTON_TEXT = 'לקבלת הצעה';

export const LOAN_SUGGESTION_INTRODUCTION = 'מגיע לך';

export const INFO_SECTION = {
  myLoan: 'ההלוואות שלי',
  cardRating: 'הצעות לשיפור דירוג האשראי',
  loanOffer: 'ייעוץ לגבי הצעה להלוואה שקיבלת',
  searchDocuments: 'חיפוש מסמכים',
  questionAndAnswers: 'שאלות ותשובות',
  info: 'מי אנחנו',
};

export const NO_BDI = {
  CARD_TITLE: 'מידע שאולי יעניין אותך',
  LOAN_SUGGESTION: {
    description: 'מגוון פתרונות לקבלת הלוואה לכל מטרה',
    productId: '8a85313c-8a74-41c6-8bae-0493c147aef6',
  },
  CONTACT_US: {
    title: 'בואו נכיר יותר כדי לתת לך את הייעוץ הטוב ביותר',
    description: 'היועץ הדיגיטלי רוצה להכיר אותך יותר בכדי לבצע את ההתאמות עבורך',
    button: 'צרו קשר',
  },
};

export const APPROVED_LOAN_REQUEST_VIDEO = `${process.env.REACT_APP_VIDEOS_BASE_URL}/loan-request-approved.mp4`;

export const MENU_OPTION_TITLE = 'פעולות ומידע';
