export function dateTimeFormat(date: Date) {
  const options: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('de-DE', options).format(date);
}

export function isValidDateTimeFormat(str: unknown) {
  if (typeof str !== 'string') {
    return false;
  }
  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;
  return regex.test(str);
}

export function formatDate(date: Date | string): string {
  date = new Date(date);
  const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function displayedDate(date: Date | string) {
  date = new Date(date);
  const day = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}`;
  const month = `${date.getMonth() + 1 < 10 ? '0' : ''}${date.getMonth() + 1}`;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export const secondsFormat = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds); // specify value for SECONDS here
  return date.toISOString().substring(14, 19);
};
