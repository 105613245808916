import { Link, useNavigate } from 'react-router-dom';

import { GenericLeftArrow } from '../../assets/genericLeftArrow';
import { GenericLinearRangeBar } from '../../assets/genericLinearRangeBar';
import { useCreditRatingData } from '../../hooks/useCreditRatingData';
import { ICreditRatingDetail, ReportEssence } from '../../interfaces';
import { reportEssenceByCategories } from '../../utils/reportEssenceUtil';

import { COLORS, CONSULTANT, CONTINUE_CONSULTING } from '../../constants';

const InformationSection = ({ creditRatingDetails }:{creditRatingDetails:ICreditRatingDetail[] }) => {
  const navigate = useNavigate();
  return (
    <div>
      {creditRatingDetails.map((ratingDetail, index) => {
        const { color, rangeX, severity } = useCreditRatingData(ratingDetail.color);
        const description = ratingDetail.description.slice(1, ratingDetail.description.length - 1);

        return (
          <div key={index} className='w-full rounded-[10px] mt-[14px] px-[17px] py-[18px] flex flex-col bg-white'>
            <div className='flex justify-between'>
              <div>
                <h2 className='font-bold text-sm'>{ratingDetail.title}</h2>
                <p className='text-greyed leading-5 text-sm'>{description}</p>
              </div>
              <div className='flex gap-2 pt-1'>
                <span className='text-xs font-semibold' style={{ color }}>{severity}</span>
                <GenericLinearRangeBar rangeX={rangeX} />
              </div>
            </div>
            {ratingDetail.consult && (
            <div className='flex items-center justify-center gap-2 m-auto mt-4 font-semibold w-full text-sm border-t-[1px] border-t-[#EDEEF2]  text-buttonBlue'>
              <Link className='flex items-center mt-4 gap-2' to={CONSULTANT}>
                {CONTINUE_CONSULTING}
                <GenericLeftArrow strokeColor={COLORS.DEEP_SKY_BLUE} />
              </Link>
            </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

const CreditRatingData = (reportEssence: ReportEssence) => (
  <div className='mt-[75px] mb-5'>
    {Object.values(reportEssenceByCategories(reportEssence))
      .sort(((firstCategory, secondCategory) => firstCategory.order - secondCategory.order))
      .map((category, index) => (
        <div key={index}>
          <div className='mt-[54px] flex items-center gap-2'>
            <div className='bg-white rounded-full p-2'>
              <category.icon />
            </div>
            <h2 className='text-white font-bold'>{category.title}</h2>
          </div>
          <InformationSection creditRatingDetails={category.content} />
        </div>
      ))}
  </div>
);

export default CreditRatingData;
