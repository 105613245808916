import { useEffect, useRef } from 'react';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Collapse from '../Collapse';
import LoadingSpinner from '../Loading/LoadingSpinner';
import SectionForm from './SectionForm';

import useApiRequests from '../../hooks/useApiRequests';
import { User } from '../../interfaces';
import { personalProfileStore, userStore } from '../../store';
import DetailsLists from '../../utils/personalProfileDetails';
import { updateDetails } from '../../utils/toast';
import { updateUserUtil } from '../../utils/updateUserUtil';

import { DEPENDANT_ITEM, DEPENDANT_LIST, SECTION as personalProfileSection, UPDATE_DETAILS_MESSAGE } from '../../constants';

const Details = () => {
  const ref = useRef(null);

  const { setUser, user } = userStore();
  const { loading, putPersonalProfile } = useApiRequests();
  const { hasProfileChanged, setHasProfileChanged } = personalProfileStore();

  const formReturned = useForm({
    mode: 'onChange',
  });

  useEffect(() => () => setHasProfileChanged(false), []);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      setHasProfileChanged(false);
      updateUserUtil(data);
      const response = await putPersonalProfile(data);
      if (response === 'updated') {
        if (user) {
          const newUser: User = { ...user, ...data };

          if (DEPENDANT_ITEM in data && !JSON.parse(data[DEPENDANT_ITEM])) {
            DEPENDANT_LIST.forEach((key) => {
              delete newUser[key];
            });
          }

          setUser({
            ...newUser,
            fullName: `${newUser?.firstname} ${newUser?.lastname}`,
            initials: `${newUser.firstname && newUser.firstname[0]}${newUser.lastname && newUser.lastname[0]}`,
          });
        }
        updateDetails('success', UPDATE_DETAILS_MESSAGE.SUCCESS);
      } else {
        updateDetails('error', UPDATE_DETAILS_MESSAGE.ERROR);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div ref={ref} className='relative h-full'>
      {
        Object.keys(personalProfileSection).map((sectionName, i) => {
          const sectionLabel = personalProfileSection[sectionName as keyof typeof personalProfileSection].LABEL;
          return (
            <Collapse
              key={sectionLabel}
              boundaryRef={ref}
              canToggle={!hasProfileChanged}
              label={sectionLabel}
              type='primary'
            >
              {loading ? (
                <div className='flex justify-center mb-8 '>
                  <LoadingSpinner />
                </div>
              )
                : (
                  <SectionForm
                    inputList={DetailsLists[i]()}
                    isLastSection={DetailsLists.length - 1 === i}
                    loading={loading}
                    onSubmit={onSubmit}
                    {...formReturned}
                  />
                )}
            </Collapse>
          );
        })
      }
    </div>
  );
};

export default Details;
