import Collapse from '../Collapse';
import CollapseHeader from './CollapseHeader';

import { InfoItem } from '../../interfaces';
import extractTextFromHTML from '../../utils/extractTextFromHTML';

import { ReactComponent as Minus } from '../../assets/icons/minus.svg';
import { ReactComponent as Plus } from '../../assets/icons/plus.svg';

interface IContentCollapse {
  list: InfoItem[];
}

const ArticleCollapses = ({ list }: IContentCollapse) => (
  <>
    {list.map(({ description, id, movie, subject }) => (
      <Collapse
        key={`info-item-${id}`}
        customIcons={{
          open: Plus,
          close: Minus,
        }}
        label={<CollapseHeader movie={movie} subject={subject} />}
        type='info'
      >
        <div className='text-sm leading-5 pb-collapse'>{extractTextFromHTML(description)}</div>
      </Collapse>
    ))}

  </>
);

export default ArticleCollapses;
