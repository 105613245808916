import React, { useState } from 'react';

import RequestSelect from './ProductsSelection';
import SentState from './SentState';

import { PostOpportunityBody } from '../../interfaces';

import { CUSTOMER_SERVICE } from '../../constants';

interface IRequestProps {
  postContactUsRequest: ({ description, product }: PostOpportunityBody) => Promise<void>
}

const Request = ({ postContactUsRequest }: IRequestProps) => {
  const [hasSentRequest, setHasSentRequest] = useState(false);
  const [requestDetails, setRequestDetails] = useState({
    product: '',
    description: '',
  });

  const onChangeDetail = (e: React.ChangeEvent<HTMLSelectElement | HTMLTextAreaElement >, inputField: keyof typeof requestDetails) => {
    setRequestDetails(prevRequestDetails => ({ ...prevRequestDetails, [inputField]: e.target.value }));
  };

  const hasRequestDetails = requestDetails.product && requestDetails.description.trim();

  const onSubmit = () => {
    if (hasRequestDetails) {
      postContactUsRequest(requestDetails).then(() => {
        setHasSentRequest(true);
        setRequestDetails({
          product: '',
          description: '',
        });
      });
    }
  };

  if (hasSentRequest) return <SentState />;

  return (
    <div className='flex flex-col py-7'>
      <span className='font-bold'>{CUSTOMER_SERVICE.request.title}</span>
      <RequestSelect service={requestDetails.product} onChange={(e) => onChangeDetail(e, 'product')} />
      <textarea
        className='mt-4 border border-lightGrey focus:outline-buttonBlue rounded-lg p-3 text-small'
        cols={30}
        maxLength={1000}
        placeholder={CUSTOMER_SERVICE.request.textareaPlaceholder}
        rows={6}
        onChange={(e) => onChangeDetail(e, 'description')}
      />
      <span className='mt-2 mr-2 text-[12px]'>{CUSTOMER_SERVICE.request.message}</span>
      <div className='mt-auto '>
        <button
          className='w-full bg-buttonBlue text-white rounded-3xl py-3 px-16 mt-10 disabled:opacity-50'
          disabled={!hasRequestDetails}
          type='button'
          onClick={onSubmit}
        >
          {CUSTOMER_SERVICE.request.send}
        </button>
      </div>
    </div>
  );
};

export default Request;
