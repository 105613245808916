import { LOANS_OFFERS_SUB_TITLE, LOANS_OFFERS_TITLE } from '../../constants';

const PreviousLoanHeader = () => (
  <div className='mt-5 mr-6 text-white '>
    <h1 className='font-bold text-xl'>{LOANS_OFFERS_TITLE}</h1>
    <span className='font-light text-sm'>{LOANS_OFFERS_SUB_TITLE}</span>
  </div>
);

export default PreviousLoanHeader;
