import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { InfoItem } from '../../interfaces';
import extractTextFromHTML from '../../utils/extractTextFromHTML';
import { videoPlayerPopupOptions } from '../../utils/popup';

import { ReactComponent as Play } from '../../assets/icons/play.svg';

const CollapseHeader = ({ movie, subject }: Pick<InfoItem, 'subject' | 'movie'>) => {
  const handleVideoTooltip = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    withReactContent(Swal).fire(
      videoPlayerPopupOptions(subject, extractTextFromHTML(movie)),
    );
  };

  return (
    <div className='flex items-center gap-x-2'>
      {subject}
      {movie ? (
        <button type='button' onClick={handleVideoTooltip}>
          <Play />
        </button>
      ) : null}
    </div>
  );
};

export default CollapseHeader;
