import { Link, useNavigate } from 'react-router-dom';

import ProfileIcon from '../ProfileIcon';

import useApiRequests from '../../hooks/useApiRequests';
import { articlesStore, bdiStore, uiLogicStore, userStore } from '../../store';
import { menuOptions } from '../../utils/MenuDetails';

import { ReactComponent as Logout } from '../../assets/icons/logout.svg';
import { ReactComponent as MenuCloseButton } from '../../assets/icons/X.svg';

import { LOGIN } from '../../constants';

const Menu = () => {
  const navigate = useNavigate();
  const { logOut, user } = userStore();
  const { reset: resetBdi } = bdiStore();
  const { reset: resetArticles } = articlesStore();
  const { isApp, menuOpen, toggleMenuOpen } = uiLogicStore(state => state);
  const { removeRefreshToken } = useApiRequests();

  const handleLogOut = async () => {
    sessionStorage.removeItem('accessToken');
    await removeRefreshToken();
    logOut();
    resetBdi();
    resetArticles();
    toggleMenuOpen();
    if (isApp) {
      (window as any).Android?.logout();
    } else {
      navigate(LOGIN);
    }
  };

  return (
    <div className={
      `fixed h-full w-0 flex flex-col transition-all ease-in-out duration-500 layout z-50 overflow-auto
      ${menuOpen ? 'w-full opacity-100 pointer-events-auto' : 'w-0 opacity-20 pointer-events-none'} `
}
    >
      <div className='grid grid-cols-3'>
        <button className='self-start pr-5 pt-5' type='button' onClick={toggleMenuOpen}>
          <MenuCloseButton />
        </button>
        <div className='flex flex-col items-center mt-12'>
          <ProfileIcon type='menu' />
          <p className='text-white mt-[11px] whitespace-nowrap font-bold leading-small text-small'>{user?.fullName || ''}</p>
          <p className='text-white mt-[6px] whitespace-nowrap font-bold leading-small text-small'>{user?.email || ''}</p>
        </div>
        <div className='flex self-start justify-end pl-5 pt-5'>
          <button
            type='button'
            onClick={handleLogOut}
          >
            <Logout />
          </button>
        </div>
      </div>
      <div className='mt-8 w-full rounded-tr-[32px] rounded-tl-[32px] bg-white z-[1001] py-7 flex flex-col flex-grow'>
        {
          menuOptions.map(option => (
            <Link
              key={option.text}
              className='pr-[33px] whitespace-nowrap items-center text-sm font-medium py-5 flex border-b-buttonBlue border-b-[0.5px]'
              to={option.navigationPath}
              onClick={toggleMenuOpen}
            >
              {option.text}
            </Link>
          ))
        }
      </div>
    </div>
  );
};
export default Menu;
