import React, { memo, MutableRefObject, useEffect, useRef, useState } from 'react';

import useClickOutside from '../../hooks/useClickOutside';

import { ReactComponent as ChevronDown } from '../../assets/icons/chevronDown.svg';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevronUp.svg';

type collapse = 'primary' | 'info'

interface CollapseProps<T> {
  boundaryRef?: MutableRefObject<T | null>
  canToggle?: boolean
  children: React.ReactNode
  className?: string
  customIcons?: {
      open: React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
      close: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  }
  label: string | React.ReactNode
  scrollIntoView?: boolean
  type: collapse
}

const Collapse = <T extends HTMLElement, >({
  boundaryRef,
  canToggle = true,
  children,
  className,
  customIcons = { open: ChevronDown, close: ChevronUp },
  label,
  scrollIntoView = false,
  type,
}: CollapseProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);

  const wrapperRef = useClickOutside(() => setTimeout(() => canToggle && setIsOpen(false), 0), boundaryRef) as MutableRefObject<HTMLDivElement | null>;

  const toggle = () => {
    if (canToggle) {
      setIsOpen((prevIsOpen) => {
        const nextState = !prevIsOpen;
        if (nextState && scrollIntoView) {
          setTimeout(() => {
            contentRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }, 200);
        }
        return nextState;
      });
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        setContentHeight(contentRef.current?.scrollHeight || 0);
      });

      resizeObserver.observe(contentRef.current);

      // Cleanup observer
      return () => {
        resizeObserver.disconnect();
      };
    }
    return () => {};
  }, [children]);

  return (
    <div
      ref={wrapperRef}
      className={`w-full border-b border-t-[#F2F2F2] hover:cursor-pointer last:border-none ${className || ''}`}
    >
      <div
        aria-expanded={isOpen}
        className='flex flex-col w-full focus:outline-none py-collapse'
        role='button'
        tabIndex={0}
        onClick={toggle}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            toggle();
          }
        }}
      >
        <div className='flex justify-between w-full'>
          <div className={type === 'info' ? 'collapse-info' : 'collapse-primary'}>{label}</div>
          {isOpen ? <customIcons.close /> : <customIcons.open />}
        </div>
      </div>
      <div
        ref={contentRef}
        className='overflow-hidden w-full transition-[max-height] duration-300 ease-in-out text-right'
        style={{
          maxHeight: isOpen
            ? `${contentHeight}px`
            : '0px',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default memo(Collapse);
