import React, { useEffect, useRef, useState } from 'react';

import LoadingSpinner from '../Loading/LoadingSpinner';

import { secondsFormat } from '../../utils/dateUtils';

import { ReactComponent as Stop } from '../../assets/icons/stopVideo.svg';
import { ReactComponent as Play } from '../../assets/icons/videoPlay.svg';

interface IVideoPlayerProps extends React.VideoHTMLAttributes<HTMLVideoElement>{
  videoUrl: string;
}

const VideoPlayer = ({ videoUrl, ...videoProperties }: IVideoPlayerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [preLoading, setPreLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [showControls, setShowControls] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const controlsTimeout = useRef<any| null>(null);

  const handlePlayPause = () => {
    if (videoRef.current && !loading) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  };

  const handleSeek = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newTime = parseFloat(event.target.value);
    if (videoRef.current) {
      videoRef.current.currentTime = newTime;
      setCurrentTime(newTime);
    }
  };

  const toggleControls = () => {
    setShowControls(true);
    if (controlsTimeout.current) {
      clearTimeout(controlsTimeout.current);
    }
    controlsTimeout.current = setTimeout(() => setShowControls(false), 3000);
  };

  const handleEnded = (e: React.SyntheticEvent<HTMLVideoElement, Event>) => {
    setIsPlaying(false);
    setShowControls(true);
    if (typeof videoProperties?.onEnded === 'function') videoProperties.onEnded(e);
  };

  const handleLoadedMetadata = () => {
    if (videoRef.current) {
      setShowControls(true);
      setPreLoading(false);
      setLoading(false);
      setDuration(videoRef.current.duration);
    }
  };

  const handleBuffering = () => {
    setLoading(true);
  };

  const handlePlaying = () => {
    setLoading(false); // Video has resumed playback
  };

  useEffect(() => {
    if (isPlaying) {
      controlsTimeout.current = setTimeout(() => setShowControls(false), 3000);
    }
    return () => {
      if (controlsTimeout.current) clearTimeout(controlsTimeout.current);
    };
  }, [isPlaying]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current
        .play()
        .catch((err) => {
          console.warn('Autoplay failed, requiring user interaction:', err);
        });
    }
  }, []);

  return (
    <div
      className='relative w-full mx-auto'
      onMouseMove={toggleControls}
    >
      {loading && (
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
        <LoadingSpinner />
      </div>
      )}
      <video
        ref={videoRef}
        className={`w-full bg-transparent ${preLoading ? 'invisible' : 'visible'}`}
        {...videoProperties}
        onDoubleClick={handlePlayPause}
        onEnded={handleEnded}
        onLoadedMetadata={handleLoadedMetadata}
        onPlaying={handlePlaying}
        onTimeUpdate={handleTimeUpdate}
        onWaiting={handleBuffering}
      >
        <source src={videoUrl} type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      {showControls && (
        <div className='absolute bottom-0 left-0 w-full bg-gradient-to-t from-[#00000045] to-transparent p-2 transition-opacity'>
          <div className='flex w-full'>
            <div className='flex-grow pr-5'>
              <input
                className='video-slider h-1 bg-gray-700 rounded-lg cursor-pointer appearance-none direction-ltr w-full align-middle'
                max={duration}
                min='0'
                step='0.1'
                style={{
                  background: `linear-gradient(to right, white ${(currentTime / duration) * 100}%, #ffffff80 ${(currentTime / duration) * 100}%)`,
                }}
                type='range'
                value={currentTime}
                onChange={handleSeek}
              />
              <div className='flex justify-between text-white text-sm font-semibold'>
                <span>{secondsFormat(duration)}</span>
                <span>{secondsFormat(currentTime)}</span>
              </div>
            </div>
            <button
              className='flex text-white px-3 py-1 focus:outline-none'
              type='button'
              onClick={handlePlayPause}
            >
              {videoRef.current && videoRef.current.paused ? <Play /> : <Stop /> }
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(VideoPlayer);
