import { useCallback, useEffect } from 'react';

import MainCarouselCard from './Carousel/MainCarouselCard';
import Carousel from '../Carousel';
import CreditRating from '../CreditRating';
import Suggestion from '../Suggestion';
import LoanSuggestion from '../Suggestion/LoanSuggestion';
import LoanRequest from './LoanRequest';
import OptionsMenu from './OptionsMenu';

import { PostOpportunityBody } from '../../interfaces';
import { bdiStore, userStore } from '../../store';
import { insightToCarousel, recommendationToCarousel } from '../../utils/carouselMapper';

import handHoldingPen from '../../assets/images/handHoldingPen.png';

import { PERSONAL_INSIGHTS, PERSONAL_RECOMMENDATIONS } from '../../constants';

import '../../styles/slickCarousel.css';

interface IMainContentProps {
  getMainPageData: () => Promise<any>
  handleContact: ({ description, product }: PostOpportunityBody) => Promise<void>
}

const MainContent = ({ getMainPageData, handleContact }:IMainContentProps) => {
  const {
    loanRequests,
    setLoanRequests,
    user,
  } = userStore();

  const {
    insights,
    loans,
    recommendations,
    setInsights,
    setLoans,
    setRecommendations,
  } = bdiStore();

  useEffect(() => {
    getMainPageData().then((data) => {
      setLoanRequests(data?.loanRequests);
      setRecommendations(data?.recommendations);
      setLoans(data?.loans);
      setInsights(data?.insights);
    });
  }, []);

  const SuggestionComponent = {
    offers: <Suggestion />,
    offer: <LoanSuggestion
      description={loans && loans.length >= 1 ? loans[0].productName : ''}
      handleContent={() => handleContact({ product: loans && loans.length >= 1 ? loans[0].product : '' })}
      image={handHoldingPen}
    />,
  };

  const SuggestionContent = useCallback(() => {
    if (loans) {
      if (loans.length === 0) return null;

      if (loans.length === 1) return SuggestionComponent.offer;
    }

    return SuggestionComponent.offers;
  }, [JSON.stringify(loans)]);

  const hasLoanRequests = !!loanRequests && loanRequests.length > 0;

  return (
    <>
      {hasLoanRequests && loanRequests.map((loanRequest, index) => (
        <LoanRequest key={`loanRequest-${index}`} index={index} loanRequest={loanRequest} />
      ))}
      {insights && insights.length > 0 && (
        <div className='w-screen mb-[20px]'>
          <Carousel
            CarouselCardComponent={MainCarouselCard}
            content={insights.map(insightToCarousel)}
            title={PERSONAL_INSIGHTS}
          />
        </div>
      )}
      {!hasLoanRequests && <SuggestionContent />}
      {user?.bdiRating ? <CreditRating /> : null}
      {recommendations && recommendations?.length > 0 && (
        <div className='w-screen mb-[20px]'>
          <Carousel
            CarouselCardComponent={MainCarouselCard}
            content={recommendations.map(recommendationToCarousel)}
            title={PERSONAL_RECOMMENDATIONS}
          />
        </div>
      )}
      <OptionsMenu />
    </>
  );
};

export default MainContent;
